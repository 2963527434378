import {Routes, Route, BrowserRouter} from "react-router-dom";
import Trader from "./Trader";
import Product from "./Product";
import Layout from "./Layout";

const Home = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout />}></Route>
                <Route path='/traders' element={<Trader />}></Route>
                <Route path='products' element={<Product />}></Route>

            </Routes>
        </BrowserRouter>
    )
}

export default Home