import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

const ProductDataTable = () => {

  const navigate = useNavigate();

//AG-GRID============================================
  const [rowData, setRowData] = useState([
    {prdtid:0, prdtname:'', prdtdescription:'', prdtrate:0, prdtvalue:0}
  ]);


  const [columnDefs, setColumnDefs] = useState([
    {field:'prdtname'},
    {field:'prdtdescription'},
    {field:'prdtrate'},
    {field:'prdtvalue'},
    {field:'prdtid'}
  ])

  useEffect(() => {
    fetch("https://domainopticals.com/api/products")
    .then(result => result.json()) 
    .then(rowData => setRowData(rowData))
  }, []);

  return (

    <div className='ag-theme-alphine' style={{height:500}}>
     
      <AgGridReact 
        rowData={rowData}
        columnDefs={columnDefs}
      />
      
    </div>
  );

  }
export default ProductDataTable;