import { Outlet, Link } from "react-router-dom";

const Layout = () => {

return (
    <>
    <nav>
        <ul>
            <li>
                <Link to="/traders">Traders</Link>
            </li>
            <li>
                <Link to="/products">Products</Link>
            </li>
        </ul>
    </nav>
    </>
)

}

export default Layout