const Trader = () => {
    return(
        <>
            <h1>
                Trader Details
            </h1>

        </>
    )
}

export default Trader

